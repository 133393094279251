import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 0;
`;

export const IconCustom = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 2px;
    width: 20px;
    height: 3px;
    background: #00000099;
  }
  &::after {
    content: '';
    position: absolute;
    top: 14px;
    left: 2px;
    width: 20px;
    height: 3px;
    background: #00000099;
  }
`;
