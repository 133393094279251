/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller, Control } from 'react-hook-form';

import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';

type Props = TextFieldProps & {
  control: Control<Record<string, any>> | undefined;
  name: string;
  type?: string;
  label?: string;
  error?: any;
  variant: 'outlined' | 'filled' | 'standard';
  style?: React.CSSProperties;
  size?: 'medium' | 'small';
};

const InputHook: React.FC<Props> = ({
  control,
  name,
  type = 'text',
  label,
  error,
  variant,
  style,
  size,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <TextField
            onChange={onChange}
            value={value}
            type={type}
            label={label}
            variant={variant}
            style={{ marginBottom: 5 }}
            error={!!error[name]?.message}
            size={size}
            {...rest}
          />
        )}
      />
      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};

export default InputHook;
