import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import * as yup from 'yup';

import logoImg from '~/assets/logo.png';
import FooterDescription from '~/components/FooterDescription';
import InputHook from '~/components/InputHook';
import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import { AnimationContainer, Container, SignInButton } from './styles';

interface IFormInput {
  cpf: string;
  password: string;
}

const schema = yup.object().shape({
  cpf: yup.string().required('O CPF é obrigatório'),
  password: yup.string().required('Senha obrigatória'),
});

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        await signIn({ cpf: data.cpf, password: data.password });

        // history.push('/files');
      } catch (err: any) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <AnimationContainer>
        <img src={logoImg} alt="Coopern" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Login</h1>

          <InputHook
            name="cpf"
            type="text"
            label="Seu CPF"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <Box position="relative" flexDirection="column" width="100%">
            <InputHook
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Senha"
              variant="outlined"
              size="small"
              control={control}
              error={errors}
              style={{ marginBottom: 15, width: '100%' }}
              disabled={!!loading}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              style={{ position: 'absolute', top: 8, right: 8 }}
              size="small"
            >
              <RemoveRedEye style={{ width: 20, height: 20 }} />
            </IconButton>
          </Box>

          <Box>
            <SignInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size="20px" /> : 'Entrar'}
            </SignInButton>
          </Box>

          <Link to="/forgot-password">Esqueci minha senha</Link>
        </form>

        <FooterDescription />
      </AnimationContainer>
    </Container>
  );
};

export default SignIn;
