import React from 'react';

import { ThemeProvider } from '@material-ui/core';

import theme from '~/styles/theme';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default AppProvider;
