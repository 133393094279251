import { Box, Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const TypographyCustom = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

export const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const BoxContainer = withStyles({
  root: {
    paddingLeft: 16,
  },
})(Box);

export const AccordionSummary = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderBottom: '1px solid#0000001F',
    marginBottom: 1,
    padding: 0,
    minHeight: 64,
    '&$expanded': {
      minHeight: 64,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

export const useStyles = makeStyles({
  listIconDoc: {
    color: '#929292',
    height: 30,
    marginRight: 10,
  },
  listTitle: {
    color: '#000000DE',
    fontSize: '16px',
    lineHeight: '24px',
  },
  listSubtitle: {
    color: '#00000099',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  listIconButton: {
    marginLeft: '16px',
  },
  listContent: {
    borderBottom: '1px solid #0000001F',
    paddingBottom: '14px',
    paddingTop: '14px',
  },
});
