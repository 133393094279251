import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ProfileButton = styled(Button)`
  color: #fff !important;
  height: 42px !important;
  min-width: 100% !important;
  box-shadow: none !important;
  margin-top: 15px !important;
`;
