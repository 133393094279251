import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #F2F2F2;
    color: #333333;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }

  body, input, button {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: bold;
  }

  button {
    cursor: pointer;
  }

  @-webkit-keyframes autofill {
    to {
        font-family: 'Open Sans', sans-serif;
        background: transparent;
        font-size: 16px;
      }
  }
  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-text-size-adjust: 18px !important;
  }
`;
