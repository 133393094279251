import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  list: {
    width: 320,
  },
  fullList: {
    width: 'auto',
  },
  textColor: {
    color: '#ffffff',
  },
  title: {
    fontSize: 20,
    fontWeight: 'normal',
  },
  icon: {
    marginLeft: 'auto',
  },
  ml: {
    marginLeft: '10px',
  },
  sizeIcon: {
    height: '24px',
    opacity: 0.6,
  },
  welcomeContainer: {
    padding: '11px 16px',
    minHeight: '74px',
  },
  welcomeTitle: {
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto, sans-serif',
  },
  welcomeText: {
    fontSize: '13px',
    color: '#00000099',
    fontFamily: 'Roboto, sans-serif',
  },
  menuIcon: {
    width: '24px',
    minWidth: '24px',
    marginRight: '16px',
    fill: '#333333 !important',
  },
  boxLogout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '70px',
    marginBottom: '40px',
  },
  buttonLogout: {
    width: '80%',
    height: '44px',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
  },
  listItem: {
    width: '100%',
    height: '42px',
    padding: '0 16px',
    color: '#333333 !important',
    fontSize: '14px !important',
    transition: 'all 0.2s',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:active': {
      backgroundColor: '#d6f6df',
      color: '#0e9f5d !important',
    },
  },
});
