import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress } from '@material-ui/core';
import * as yup from 'yup';

import logoImg from '~/assets/logo.png';
import FooterDescription from '~/components/FooterDescription';
import InputHook from '~/components/InputHook';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import { AnimationContainer, Container, SignInButton } from './styles';

interface IFormInput {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().required('O e-mail é obrigatório'),
});

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        const { email } = data;

        await api.post('/auth/forgot-password', {
          email,
        });

        addToast({
          type: 'info',
          title: 'E-mail enviado!',
          description:
            'Verifique no seu e-mail as instruções para redefinir sua senha.',
        });

        history.push('/');
      } catch (err: any) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Error!',
          description:
            err.response?.data?.error ||
            'Erro no servidor, tente novamente mais tarde.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <AnimationContainer>
        <img src={logoImg} alt="Coopern" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Esqueceu sua senha?</h1>

          <InputHook
            name="email"
            type="text"
            label="Digite seu e-mail"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <Box>
            <SignInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size="20px" /> : 'Enviar'}
            </SignInButton>
          </Box>

          <Link to="/">Lembrou? Clique aqui!</Link>
        </form>

        <FooterDescription />
      </AnimationContainer>
    </Container>
  );
};

export default ForgotPassword;
