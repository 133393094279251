import React, { useCallback, useEffect, useState } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CloudDownload as CloudDownloadIcon,
  Description as DescriptionIcon,
  RemoveRedEye as EyeIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import FileDownload from 'js-file-download';

import { useAuth } from '~/hooks/auth';
import api from '~/services/api';

import {
  TypographyCustom,
  Accordion,
  BoxContainer,
  AccordionDetails,
  AccordionSummary,
  useStyles,
} from './materialStyles';
import { Container, IconCustom } from './styles';

interface IFile {
  year: number;
  data: Array<{
    id: number;
    name: string;
    short_name: string;
    file_date: string;
    formatted_file_date: string;
    user_id: number;
    created_at: string;
    url: string;
  }>;
}

const MyFiles: React.FC = () => {
  const classes = useStyles();
  const { signOut } = useAuth();

  const [expanded, setExpanded] = useState('panel0');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get<IFile[]>('my-files')
      .then((response) => {
        const formattedData = response.data?.map<IFile>((item) => ({
          ...item,
          data: item.data?.map((file) => ({
            ...file,
            formatted_file_date: format(
              new Date(
                Number(file.file_date.split('-')[0]),
                Number(file.file_date.split('-')[1]) - 1,
                1,
              ),
              'MMMM yyyy',
              {
                locale: ptBr,
              },
            ),
          })),
        }));
        setFiles(formattedData || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.error === 'O token está expirado') {
          signOut();
        }
      });
  }, [signOut]);

  const handleChange = useCallback((panel: string) => {
    setExpanded(panel);
  }, []);

  const handleDownload = useCallback((fileName: string) => {
    api
      .get(`my-files/download/${fileName}`, {
        responseType: 'blob',
      })
      .then((response) => {
        FileDownload(response.data, fileName);
      });
  }, []);

  if (files.length <= 0 && loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={10}
      >
        <CircularProgress />
        <p>Carregando...</p>
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="subtitle2" align="center">
        Visualize ou baixe seus arquivos abaixo
      </Typography>

      <BoxContainer>
        {files?.length > 0 ? (
          files?.map((item, index) => (
            <Accordion
              key={`panel-${item.year}`}
              square
              expanded={expanded === `panel${index}`}
              onChange={() => handleChange(`panel${index}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <TypographyCustom>{item.year}</TypographyCustom>
                <IconCustom />
              </AccordionSummary>
              <AccordionDetails>
                {item.data?.map((file) => {
                  return (
                    <Box
                      key={`file-${file.id}`}
                      display="flex"
                      alignItems="center"
                      width="100%"
                    >
                      <a
                        href={file.url}
                        target="_blank"
                        title="Ver arquivo"
                        rel="noopener noreferrer"
                      >
                        <DescriptionIcon
                          className={clsx(classes.listIconDoc)}
                        />
                      </a>

                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        className={clsx(classes.listContent)}
                      >
                        <a
                          href={file.url}
                          target="_blank"
                          title="Ver arquivo"
                          rel="noopener noreferrer"
                        >
                          <Box className="mr-auto">
                            <Typography className={clsx(classes.listTitle)}>
                              {file.short_name}
                            </Typography>
                            <Typography className={clsx(classes.listSubtitle)}>
                              {file.formatted_file_date}
                            </Typography>
                          </Box>
                        </a>

                        <Box
                          display="flex"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <a
                            href={file.url}
                            target="_blank"
                            title="Ver arquivo"
                            rel="noopener noreferrer"
                          >
                            <IconButton
                              color="primary"
                              className={clsx(classes.listIconButton)}
                              aria-label="notification"
                              edge="end"
                              size="small"
                            >
                              <EyeIcon />
                            </IconButton>
                          </a>
                          <IconButton
                            title="Baixar arquivo"
                            color="primary"
                            className={clsx(classes.listIconButton)}
                            aria-label="notification"
                            edge="end"
                            size="small"
                            onClick={() => handleDownload(file.name)}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={5}
          >
            <p>Você não possui nenhum arquivo.</p>
            <br />
            <small>Eles serão exibidos aqui!</small>
          </Box>
        )}
      </BoxContainer>
    </Container>
  );
};

export default MyFiles;
