import React, { useMemo } from 'react';

import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import interativaImg from '~/assets/interativa.png';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    maxWidth: '250px',
  },
  version: {
    fontSize: '15px',
    fontWeight: 600,
  },
});

const FooterDescription: React.FC = () => {
  const classes = useStyles();
  const version = useMemo(() => {
    return process.env.REACT_APP_VERSION || '-';
  }, []);

  return (
    <Box color="text.secondary" className={clsx(classes.container)}>
      <Box component="p" className={clsx(classes.description)}>
        Cooperativa de Trabalho e Serviços do Rio Grande do Norte
      </Box>
      <Box component="strong" className={clsx(classes.version)}>
        Versão {version}
      </Box>
      <Box display="flex" justifyContent="center">
        <Link
          href="https://www.interativadigital.com.br/"
          title="Interativa Digital - Desde 1998 desenvolvendo os melhores projetos para Internet."
          target="_blank"
        >
          <img
            src={interativaImg}
            alt="Interativa"
            height="30px"
            width="auto"
          />
        </Link>
      </Box>
    </Box>
  );
};

export default FooterDescription;
