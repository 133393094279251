import { Button } from '@material-ui/core';
import { shade } from 'polished';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0 11px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // animation: ${appearFromLeft} 1s;

  > img {
    max-width: 190px;
  }

  form {
    display: flex;
    flex-direction: column;

    margin: 45px 0 35px;
    width: 100%;
    max-width: 350px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 30px 23px;
    box-shadow: 0px 6px 12px #0000001a;

    h1 {
      margin-bottom: 15px;
      font-size: 28px;
      font-weight: normal;
    }

    a {
      color: #f83539;
      display: block;
      margin-top: 23px;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
        color: ${shade(0.2, '#f83539')};
      }
    }

    p {
      width: auto !important;
      max-width: 220px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    max-width: 250px;
  }
`;

export const SignInButton = styled(Button)`
  color: #fff !important;
  height: 42px !important;
  min-width: 196px !important;
  box-shadow: none !important;
  margin-top: 15px !important;
`;
