import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ListItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import MenuIcon from '@material-ui/icons/Menu';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from 'clsx';

import facebookImg from '~/assets/facebook-square.svg';
import instagramImg from '~/assets/instagram.svg';
import FooterDescription from '~/components/FooterDescription';
import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';
import { useAddToHomescreenPrompt } from '~/utils/useAddToHomescreenPrompt';

import { useStyles } from './materialStyles';
import { Container, CustomLinkBlank } from './styles';

interface ItemMenu {
  title: string;
  icon: React.ReactElement;
  url: string;
  type?: string;
  target?: string;
}

const LayoutDefault: React.FC = ({ children }) => {
  const classes = useStyles();
  const { signOut, user } = useAuth();
  const { addToast } = useToast();
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  const itemsMenu = useMemo<ItemMenu[]>(() => {
    return [
      {
        title: 'Perfil',
        icon: <PersonIcon className={clsx(classes.menuIcon)} />,
        url: '/profile',
      },
      {
        title: 'Meus Documentos',
        icon: <DescriptionIcon className={clsx(classes.menuIcon)} />,
        url: '/files',
      },
      {
        title: 'Regimento Interno',
        icon: <DescriptionIcon className={clsx(classes.menuIcon)} />,
        url: 'https://coopern.com.br/wp-content/uploads/2024/05/regimento-interno-coopern.pdf',
        target: '_blank',
      },
      {
        title: 'Baixe o Aplicativo',
        icon: <SmartphoneIcon className={clsx(classes.menuIcon)} />,
        url: '#',
        type: 'button',
      },
      {
        title: 'Sobre este App',
        icon: <InfoIcon className={clsx(classes.menuIcon)} />,
        url: '/files',
      },
      {
        title: 'Fale com a COOPERN',
        icon: <ChatBubbleIcon className={clsx(classes.menuIcon)} />,
        url: '/contact',
      },
      {
        title: 'Site COOPERN',
        icon: <OpenInNewIcon className={clsx(classes.menuIcon)} />,
        url: 'https://coopern.com.br',
        target: '_blank',
      },
      {
        title: 'Trocar Minha Senha',
        icon: <VpnKeyIcon className={clsx(classes.menuIcon)} />,
        url: '/profile',
      },
    ];
  }, [classes.menuIcon]);

  const location = useLocation();
  const pageName = useMemo(() => {
    const findPathName = itemsMenu.find(
      (item) => item.url === location.pathname,
    );

    if (!findPathName) return '';

    return findPathName.title;
  }, [location, itemsMenu]);

  const [open, setOpen] = useState(false);

  const handleToggleDrawer = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);

  const handleSignOut = useCallback(() => {
    addToast({
      type: 'success',
      title: 'Deslogado com sucesso!',
    });
    signOut();
  }, [signOut, addToast]);

  const handleInstall = useCallback(() => {
    if (!isVisible) return;

    promptToInstall();
  }, [isVisible, promptToInstall]);

  return (
    <Container>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            className={clsx(classes.textColor)}
            aria-label="open drawer"
            onClick={() => handleToggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            className={clsx(classes.textColor, classes.title)}
          >
            {pageName}
          </Typography>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => handleToggleDrawer(false)}
        onOpen={() => handleToggleDrawer(true)}
      >
        <div
          className={clsx(classes.list)}
          role="presentation"
          onClick={() => handleToggleDrawer(false)}
          onKeyDown={() => handleToggleDrawer(false)}
        >
          <Box className={classes.welcomeContainer}>
            <Typography
              variant="subtitle1"
              noWrap
              className={classes.welcomeTitle}
            >
              Olá,
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap
              className={classes.welcomeText}
            >
              {user.name}
            </Typography>
          </Box>

          <Divider />

          <List>
            {itemsMenu.map((item) => {
              return item?.type === 'button' ? (
                <ListItem
                  key={item.title}
                  className={clsx(classes.listItem)}
                  button
                  onClick={handleInstall}
                  disabled={!isVisible}
                >
                  <ListItemIcon className={clsx(classes.menuIcon)}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ) : (
                <ListItem
                  key={item.title}
                  className={clsx(classes.listItem)}
                  button
                  component={item.target ? 'a' : Link}
                  to={item.url}
                  target={item.target}
                  href={item.url}
                >
                  <ListItemIcon className={clsx(classes.menuIcon)}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            })}
          </List>

          <Box className={clsx(classes.boxLogout)}>
            <Button
              variant="outlined"
              color="primary"
              className={clsx(classes.buttonLogout)}
              onClick={handleSignOut}
            >
              Sair
            </Button>
          </Box>

          <Box className={clsx(classes.socialLinks)}>
            <CustomLinkBlank href="https://www.facebook.com/coopernoficial">
              <img src={facebookImg} alt="Facebook Coopern" />
            </CustomLinkBlank>
            <CustomLinkBlank href="https://www.instagram.com/coopern.enfermagem">
              <img src={instagramImg} alt="Instagram Coopern" />
            </CustomLinkBlank>
          </Box>
          <FooterDescription />
        </div>
      </SwipeableDrawer>

      <Box maxWidth="700px" width="100%" marginRight="auto" marginLeft="auto">
        {children}
      </Box>
    </Container>
  );
};

export default LayoutDefault;
