import React from 'react';
import { Switch } from 'react-router-dom';

import LayoutAuth from '~/layouts/auth';
import LayoutDefault from '~/layouts/default';
import Contact from '~/pages/Contact';
import ForgotPassword from '~/pages/ForgotPassword';
import MyFiles from '~/pages/MyFiles';
import NotFound from '~/pages/NotFound';
import Profile from '~/pages/Profile';
import ResetPassword from '~/pages/ResetPassword';
import SignIn from '~/pages/SignIn';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} layout={LayoutAuth} />
    <Route path="/signin" exact component={SignIn} layout={LayoutAuth} />
    <Route
      path="/forgot-password"
      exact
      component={ForgotPassword}
      layout={LayoutAuth}
    />
    <Route
      path="/reset-password/:token"
      exact
      component={ResetPassword}
      layout={LayoutAuth}
    />

    <Route
      path="/files"
      exact
      isPrivate
      component={MyFiles}
      layout={LayoutDefault}
    />
    <Route
      path="/profile"
      exact
      isPrivate
      component={Profile}
      layout={LayoutDefault}
    />
    <Route
      path="/contact"
      exact
      isPrivate
      component={Contact}
      layout={LayoutDefault}
    />
    <Route path="/*" isNotFound component={NotFound} layout={LayoutAuth} />
  </Switch>
);

export default Routes;
