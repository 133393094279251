/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress } from '@material-ui/core';
import * as yup from 'yup';

import InputHook from '~/components/InputHook';
import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import { Container } from './styles';

interface IFormInput {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  email: yup.string().required('O e-mail é obrigatório'),
  subject: yup.string().required('O assunto é obrigatório'),
  message: yup.string().required('A mensagem é obrigatória'),
});

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();

  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: user,
  });

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        const { name, email, subject, message } = data;

        await api.post('contact', {
          name,
          email,
          subject,
          message,
        });

        addToast({
          type: 'success',
          title: 'Mensagem enviada com sucesso!',
          description: 'A resposta será enviada para o e-mail informado.',
        });

        history.push('/files');
      } catch (err: any) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            err?.response?.data?.error ||
            'Ocorreu um erro no servidor. Tente novamente',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container display="flex" flexDirection="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column">
          <InputHook
            name="name"
            type="text"
            label="Nome"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <InputHook
            name="email"
            type="email"
            label="E-mail"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <InputHook
            name="subject"
            type="text"
            label="Assunto"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <InputHook
            name="message"
            type="text"
            variant="outlined"
            placeholder="Sua mensagem..."
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
            multiline
            rows={6}
          />
        </Box>

        <Box mt={3} display="flex">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            style={{ height: '44px' }}
            disabled={!!loading}
          >
            {loading ? <CircularProgress size="20px" /> : 'Enviar'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Contact;
