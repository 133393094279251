import { ListItem } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 79px 8px 20px;
`;

export const CustomLinkBlank = styled.a.attrs({
  target: '_blank',
})`
  img {
    width: 27px;
    height: 27px;
    object-fit: contain;
    object-position: center;
  }

  & + & {
    margin-left: 20px;
  }
`;
