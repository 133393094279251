/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
} from '@material-ui/core';
import * as yup from 'yup';

import InputHook from '~/components/InputHook';
import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import { Container } from './styles';

interface IFormInput {
  name: string;
  email: string;
  phone: string;
  birthday: string;
  want_to_receive_whatsapp: boolean;
  password: string;
  password_confirmation: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function PhoneNumberFormat(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="+55 ## #####-####"
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

interface NumberFormatCustomProps {
  inputRef: (instance: any | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  email: yup.string().required('O e-mail é obrigatório'),
  phone: yup.string(),
  password: yup.string(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação incorreta'),
});

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const [phone, setPhone] = useState(user.phone);

  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...user,
      birthday: user.birthday || '',
      want_to_receive_whatsapp: user.want_to_receive_whatsapp || true,
    },
  });

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        const {
          name,
          email,
          password,
          password_confirmation,
          birthday,
          want_to_receive_whatsapp,
        } = data;

        const formData = {
          name,
          email,
          phone,
          ...(password
            ? {
                password,
                password_confirmation,
              }
            : {}),
          birthday,
          want_to_receive_whatsapp,
        };

        await api.put('profile', formData);

        updateUser({
          name,
          email,
          phone,
          birthday,
          want_to_receive_whatsapp,
        });

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description: 'Suas informações foram atualizadas com sucesso!',
        });

        setLoading(false);
      } catch (err: any) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro!',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao atualizar os seus dados. Tente novamente',
        });
      }
    },
    [addToast, updateUser, phone],
  );

  const NumberFormatCustom = useCallback(
    (props: NumberFormatCustomProps): JSX.Element => {
      const { inputRef, onChange, ...other } = props;

      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values) => {
            onChange({
              target: {
                value: values.value,
              },
            });
          }}
          isNumericString
          format="###.###.###-##"
        />
      );
    },
    [],
  );

  return (
    <Container display="flex" flexDirection="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column">
          <InputHook
            name="name"
            type="text"
            label="Nome"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <InputHook
            name="email"
            type="email"
            label="E-mail"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <TextField
            name="cpf"
            type="text"
            label="CPF"
            variant="outlined"
            size="small"
            defaultValue={user.cpf}
            disabled
            style={{ marginBottom: 15 }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
          />

          <TextField
            name="phone"
            type="text"
            label="Telefone"
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            size="small"
            style={{ marginBottom: 15 }}
            disabled={!!loading}
            InputProps={{
              inputComponent: PhoneNumberFormat,
            }}
          />

          <InputHook
            name="birthday"
            type="date"
            label="Data de nascimento"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15, width: '100%' }}
            disabled={!!loading}
          />

          <Box display="flex" alignItems="center" style={{ marginBottom: 15 }}>
            <Checkbox
              name="want_to_receive_whatsapp"
              inputRef={control.register}
              defaultChecked={user.want_to_receive_whatsapp || true}
              disabled={!!loading}
            />
            <span style={{ fontSize: 14 }}>
              Quero receber mensagens no WhatsApp
            </span>
          </Box>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <InputHook
            name="password"
            type="password"
            label="Nova senha"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15, width: '100%' }}
            disabled={!!loading}
          />
          <InputHook
            name="password_confirmation"
            type="password"
            label="Confirme sua senha"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15, width: '100%' }}
            disabled={!!loading}
          />
        </Box>

        <Box mt={6} display="flex">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            style={{ height: '44px' }}
            disabled={!!loading}
          >
            {loading ? <CircularProgress size="20px" /> : 'Atualizar'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default Profile;
