import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

const CustomTheme: ThemeOptions = createMuiTheme({
  palette: {
    primary: {
      main: '#19B66E',
    },
    secondary: {
      main: '#089E92',
    },
  },
  typography: {
    fontFamily: 'Open Sans, Roboto, sans-serif',
    fontSize: 16,
  },
});

export default CustomTheme;
