import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress } from '@material-ui/core';
import * as yup from 'yup';

import logoImg from '~/assets/logo.png';
import FooterDescription from '~/components/FooterDescription';
import InputHook from '~/components/InputHook';
import { useToast } from '~/hooks/toast';
import api from '~/services/api';

import { AnimationContainer, Container, SignInButton } from './styles';

interface IFormInput {
  password: string;
  password_confirmation: string;
}

interface Params {
  token: string;
}

const schema = yup.object().shape({
  password: yup.string().required('A senha é obrigatória'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação incorreta'),
});

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<Params>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: IFormInput) => {
      setLoading(true);

      try {
        const { password, password_confirmation } = data;

        await api.post('/auth/reset-password', {
          password,
          password_confirmation,
          token,
        });

        addToast({
          type: 'success',
          title: 'Sua senha foi redefinida com sucesso!',
          description: 'Use sua nova senha para fazer login.',
        });

        history.push('/');
      } catch (err: any) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Error!',
          description:
            err.response?.data?.error ||
            'Erro no servidor, tente novamente mais tarde.',
        });
      }
    },
    [token, addToast, history],
  );

  return (
    <Container>
      <AnimationContainer>
        <img src={logoImg} alt="Coopern" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Redefina sua senha</h1>

          <InputHook
            name="password"
            type="password"
            label="Nova senha"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <InputHook
            name="password_confirmation"
            type="password"
            label="Confirme sua senha"
            variant="outlined"
            size="small"
            control={control}
            error={errors}
            style={{ marginBottom: 15 }}
            disabled={!!loading}
          />

          <Box>
            <SignInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size="20px" /> : 'Redefinir'}
            </SignInButton>
          </Box>

          <Link to="/">Ir para tela de login</Link>
        </form>

        <FooterDescription />
      </AnimationContainer>
    </Container>
  );
};

export default ResetPassword;
