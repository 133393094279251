import React, { useEffect } from 'react';

import {
  ErrorOutline,
  CheckCircleOutline,
  InfoOutlined,
  CloseOutlined,
} from '@material-ui/icons';

import { ToastMessage, useToast } from '~/hooks/toast';

import { Container } from './styles';

interface ToastProps {
  toast: ToastMessage;
  style: object;
}

const icons = {
  info: <InfoOutlined />,
  error: <ErrorOutline />,
  success: <CheckCircleOutline />,
};

const Toast: React.FC<ToastProps> = ({ toast, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, toast.id]);

  return (
    <Container
      type={toast.type}
      className={!toast.description ? 'noDescription' : ''}
      style={style}
    >
      {icons[toast.type || 'info']}

      <div>
        <strong>{toast.title}</strong>
        {toast.description && <p>{toast.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(toast.id)}>
        <CloseOutlined />
      </button>
    </Container>
  );
};

export default Toast;
