import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  width: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 6666;
  background: transparent;

  @media (max-width: 450px) {
    min-width: 100vw;
  }
`;
