import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

const NotFound: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      padding="20px"
      textAlign="center"
    >
      <Typography color="textSecondary" variant="h1">
        404
      </Typography>
      <Typography color="textSecondary" variant="h5">
        Página não encontrada!
      </Typography>
      <Typography color="textSecondary" variant="body1">
        A página que você está procurando não existe ou ocorreu outro erro.
      </Typography>
      <Link to="/">
        <Typography color="primary">Ir para página inicial</Typography>
      </Link>
    </Box>
  );
};

export default NotFound;
